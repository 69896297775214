import clsx from 'clsx'
import { ReactComponent as ChevronLeftIcon } from '@brand/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '@brand/icons/chevron-right.svg'
import styles from './horizontal-scroll-arrow-button.module.css'

interface HorizontalScrollArrowButtonProps {
  showArrows?: boolean
  handler: (directionIndex: number, scroll: number) => void
  ariaLabel: string
  direction: string
  isActive: boolean
  scrollAmount?: number
}

export function HorizontalScrollArrowButton(
  props: HorizontalScrollArrowButtonProps
) {
  if (!props.showArrows) {
    return null
  }

  const { scrollAmount = 0 } = props
  const ChevronIcon =
    props.direction === 'left' ? ChevronLeftIcon : ChevronRightIcon
  const directionIndex = props.direction === 'left' ? -1 : 1

  return (
    <button
      onClick={() => props.handler(directionIndex, scrollAmount)}
      aria-label={props.ariaLabel}
      className={clsx(
        styles.arrowsBtn,
        !props.isActive && styles.hiddenArrowsBtn
      )}
      disabled={!props.isActive}
      type="button"
    >
      <ChevronIcon
        aria-hidden
        className={clsx(
          styles.arrowsText,
          !props.isActive && styles.arrowsTextDisabled
        )}
      />
    </button>
  )
}
