import clsx from 'clsx'
import { useRef, useState, useEffect, forwardRef, useCallback } from 'react'
import type { ForwardedRef } from 'react'
import styles from './horizontal-scroll.module.css'
import { HorizontalScrollArrowButton } from './horizontal-scroll-arrow-button'

export interface HorizontalScrollProps {
  dataTid?: string
  children?: JSX.Element[] | JSX.Element
  showArrows?: boolean
  noScrollbar?: boolean
  scrollAmount?: number
  rightArrowPositionAdjustment?: number
  // When enabled, When the last child of the list size is updated, the list will scroll to the end
  dynamicScrollingEnabled?: boolean
}

type InactiveButton = 'right' | 'left' | 'none'

// A forwarded ref can be null, a callback (function), or an object
// This returns a RefObject that can be used predictibly
// e.g. trying to access ref.current on a callback does not work
// https://non-traditional.dev/how-to-use-the-forwarded-ref-in-react-1fb108f4e6af
const useForwardedOrDefaultRef = (ref: ForwardedRef<HTMLUListElement>) => {
  const innerRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    if (!ref) return
    if (typeof ref === 'function') {
      ref(innerRef.current)
    } else {
      ref.current = innerRef.current
    }
  })

  return innerRef
}

const listHasWidth = (element: HTMLUListElement) => {
  return element.clientWidth > 0 && element.scrollWidth > 0
}

const getIsScrolledRight = (
  scrollLeft: number,
  scrollWidth: number,
  clientWidth: number
) => {
  // When checking if the element is scrolled all the way to the right,
  // in most cases, we add 1px of "tolerance" to this check.
  // Due to rounding, the max value of scrollLeft can sometimes be 1px less than
  // the difference between scrollWidth and clientWidth.
  //
  // However, if the scrollWidth only exceeds the clientWidth by 1px
  // (e.g., exposed filters on SRP desktop), introducing this 1px of tolerance causes display issues.
  const scrollRightTolerance = scrollWidth - clientWidth <= 1 ? 0 : 1

  return scrollLeft >= scrollWidth - clientWidth - scrollRightTolerance
}

export const HorizontalScroll = forwardRef<
  HTMLUListElement,
  HorizontalScrollProps
>(function HorizontalScrollWithForwardedRef(props, forwardedRef) {
  const listContainerRef = useForwardedOrDefaultRef(forwardedRef)
  // possible values for inactiveButton are 'right', 'left', or 'none' (meaning both buttons should be visible and interactive)
  const [inactiveButton, setInactiveButton] = useState<null | InactiveButton>(
    null
  )
  const listItemWidths = useRef<number[]>([])
  const scrollEndRef = useRef({
    isScrolledEndLeft: true,
    isScrolledEndRight: false,
  })

  const showBothGradients =
    !scrollEndRef.current.isScrolledEndLeft &&
    !scrollEndRef.current.isScrolledEndRight
  const showLeftGradient =
    !showBothGradients && !scrollEndRef.current.isScrolledEndLeft
  const showRightGradient =
    !showBothGradients &&
    !scrollEndRef.current.isScrolledEndRight &&
    inactiveButton === 'left'

  const updateArrows = useCallback(
    (currentScrollLocation: number) => {
      const listContainer = listContainerRef?.current

      if (
        !listContainer ||
        (listContainer.clientWidth &&
          listContainer.clientWidth === listContainer.scrollWidth &&
          !inactiveButton)
      )
        return

      if (
        listHasWidth(listContainer) &&
        listContainer.clientWidth === listContainer.scrollWidth
      ) {
        setInactiveButton(null)
      } else if (
        getIsScrolledRight(
          currentScrollLocation,
          listContainer.scrollWidth,
          listContainer.clientWidth
        )
      ) {
        setInactiveButton('right')
      } else if (currentScrollLocation <= 0) {
        setInactiveButton('left')
      } else {
        setInactiveButton('none')
      }
    },
    [inactiveButton, listContainerRef]
  )

  const handleScroll = useCallback(() => {
    const listContainer = listContainerRef?.current

    if (listContainer) {
      const currentScrollLocation = listContainer.scrollLeft

      scrollEndRef.current = {
        isScrolledEndLeft: currentScrollLocation <= 0,
        isScrolledEndRight: getIsScrolledRight(
          currentScrollLocation,
          listContainer.scrollWidth,
          listContainer.clientWidth
        ),
      }
      updateArrows(currentScrollLocation)

      // Collapses any expanded elements when scrolling (e.g. filter dropdown)
      const expandedElement: HTMLButtonElement | null =
        listContainer.querySelector('[aria-expanded="true"]')

      if (expandedElement) {
        expandedElement.click()
      }
    }
  }, [listContainerRef, updateArrows])

  useEffect(() => {
    const listContainer = listContainerRef?.current

    if (!listContainer) return

    const noOverflowButHasButtons =
      listContainer &&
      listContainer.clientWidth === listContainer.scrollWidth &&
      inactiveButton
    const overflowButNoButtons =
      listContainer &&
      listContainer.clientWidth !== listContainer.scrollWidth &&
      !inactiveButton

    if (
      listHasWidth(listContainer) &&
      (noOverflowButHasButtons || overflowButNoButtons)
    )
      handleScroll()
  })

  useEffect(() => {
    const listContainer = listContainerRef?.current
    if (!listContainer) return

    listContainer?.addEventListener('scroll', handleScroll)

    // Fixes bug where the scroll width of the ul exceeds the client width by exactly 1px
    if (listContainer.scrollWidth - listContainer.clientWidth === 1) {
      listContainer.style.paddingRight = `calc(${
        listContainer.style.paddingRight || '0px'
      } + 1px)`
    }

    handleScroll()

    return () => {
      listContainer?.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!props.dynamicScrollingEnabled) {
      return
    }
    const listContainer = listContainerRef?.current

    if (!listContainer) return

    const listItemArray = Array.from(listContainer.children)
    listItemWidths.current = listItemArray.map((el) => el.clientWidth)

    // For each child, we want to check for changes to its width.
    // If it does change, we want to "handle scroll" to ensure that the gradient / buttons update,
    // in case the changes to the width cause overflow.

    const childResizeObserver = new ResizeObserver((entries) => {
      const elementWidth = entries[0].contentBoxSize[0].inlineSize
      const index = listItemArray.indexOf(entries[0].target)

      const isWidthChange =
        Math.abs(elementWidth - listItemWidths.current[index]) >= 1

      if (isWidthChange) {
        handleScroll()
      }

      listItemWidths.current[index] = elementWidth
    })

    listItemArray
      .slice(0, listContainer.children.length - 1)
      .forEach((child) => {
        childResizeObserver.unobserve(child)
        childResizeObserver.observe(child)
      })

    // The one exception to this is the last child.
    // If an interaction with the last child due to a width change causes overflow,
    // we want to make sure we scroll all the way to the right,
    // so that that element remains fully in view.

    const lastChildResizeObserver = new ResizeObserver((entries) => {
      const scrollWidth = listContainer?.scrollWidth
      const clientWidth = listContainer?.clientWidth
      const scrollLeft = listContainer?.scrollLeft

      const elementWidth = entries[0].contentBoxSize[0].inlineSize

      const isWidthChange =
        Math.abs(
          elementWidth -
            listItemWidths.current[listItemWidths.current.length - 1]
        ) >= 1

      if (
        scrollWidth - clientWidth > scrollLeft &&
        scrollEndRef.current.isScrolledEndRight &&
        isWidthChange
      ) {
        listContainer?.scrollTo(scrollWidth - clientWidth, 0)
      }

      listItemWidths.current[listItemWidths.current.length - 1] = elementWidth
    })

    if (listContainer.children.length) {
      const lastChild =
        listContainer.children[listContainer.children.length - 1]

      lastChildResizeObserver.unobserve(lastChild)
      lastChildResizeObserver.observe(lastChild)
    }
  }, [listContainerRef, props.dynamicScrollingEnabled, handleScroll])

  function onClickScrollButton(directionIndex: number, scrollAmount: number) {
    const elem = listContainerRef.current

    if (!elem) return

    const scrollingDistance = elem.clientWidth / 2
    const currentScrollLocation =
      elem.scrollLeft + directionIndex * (scrollAmount || scrollingDistance)

    elem.scrollTo({
      behavior: 'smooth',
      left: currentScrollLocation,
    })
  }

  return (
    <div className={styles.container}>
      {inactiveButton && (
        <div className={styles.buttonsContainer}>
          {['left', 'right'].map((item) => (
            <HorizontalScrollArrowButton
              key={`scroll_${item}`}
              ariaLabel={
                item === 'left' ? 'View Previous Items' : 'View More Items'
              }
              handler={onClickScrollButton}
              direction={item}
              isActive={inactiveButton !== item}
              showArrows={props.showArrows}
              scrollAmount={props.scrollAmount}
            />
          ))}
        </div>
      )}
      {(showBothGradients || showLeftGradient || showRightGradient) && (
        <div
          className={clsx(
            styles.gradient,
            showBothGradients && styles.gradientOnBothSides,
            showLeftGradient && styles.gradientOnLeft,
            showRightGradient && styles.gradientOnRight
          )}
          style={{
            width: listContainerRef.current?.clientWidth || 0,
            height: listContainerRef.current?.clientHeight || 0,
          }}
        />
      )}
      <ul
        data-tid={props.dataTid}
        ref={listContainerRef}
        className={clsx(
          styles.list,
          !props.showArrows && styles.listMobile,
          props.noScrollbar && styles.noScrollbar
        )}
      >
        {props.children}
      </ul>
    </div>
  )
})
